html,
body,
#root {
  margin: 0;
  font-family: -apple-system,
    BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}
